import { NestAPI } from 'app/nestapi'
import Download from 'js-file-download'
import { UploadSubmission } from '@marketing-milk/interfaces'
import { AxiosResponse } from 'axios'

export type GetSubmissionParams = {
  pending: boolean
  page: number
  pageSize?: number
  sortDirection: 'ASC' | 'DESC'
}
export type Paginated<T> = { data: T[]; count: number }

export interface UploadSubmissionServiceI {
  getSubmission: (submissionID: number) => Promise<UploadSubmission>
  getSubmissions: (
    params: GetSubmissionParams
  ) => Promise<{ submissions: UploadSubmission[]; count: number }>
  updateSubmission: (submissionID: number, approved: boolean, message: string) => Promise<void>
  downloadSubmission: (submissionID: number, fileName: string) => Promise<void>
}
class UploadSubmissionServiceImpl implements UploadSubmissionServiceI {
  async getSubmission(submissionID: number): Promise<UploadSubmission> {
    return NestAPI.get(`/uploads/submissions/${submissionID}`)
  }

  async getSubmissions(
    params: GetSubmissionParams
  ): Promise<{ submissions: UploadSubmission[]; count: number }> {
    const data: Paginated<UploadSubmission> = await NestAPI.get(`/uploads/submissions`, {
      params: {
        status: params.pending ? 'pending' : 'not-pending',
        page: params.page,
        pageSize: params.pageSize || 10,
        sortDirection: params.sortDirection,
      },
    })
    const { data: submissions, count } = data
    return { submissions, count: count }
  }

  async updateSubmission(
    submissionID: number,
    approved: boolean,
    message: string = ''
  ): Promise<void> {
    if (!approved && message.length === 0) return
    await NestAPI.patch(`/uploads/submissions/${submissionID}`, {
      approved,
      message,
    })
  }
  async downloadSubmission(submissionID: number, fileName: string): Promise<void> {
    // @ts-ignore when using doNotIntercept type changes
    const file: AxiosResponse<File> = await NestAPI.get(
      `/uploads/submissions/${submissionID}/download`,
      // @ts-ignore
      { doNotIntercept: true }
    )
    Download(file.data, `${fileName}.csv`)
  }
}

export const UploadSubmissionService: UploadSubmissionServiceI = new UploadSubmissionServiceImpl()
