import Select from 'react-select'
import Redux from 'app/interface/redux'
import Connect from 'app/redux/connect'
import React, { PureComponent } from 'react'
import UserActions from 'app/redux/actions/pages/business/users'
import { NestAPI } from 'app/nestapi'
import { toast } from 'react-toastify'

interface Props extends Redux {
  available: Array<User>
}
interface State {
  id: number | null
  addingUser: boolean
}

type User = {
  id: number
  name: string
  email: string
}

class UserSearch extends PureComponent<Props> {
  state: State = {
    id: null,
    addingUser: false,
  }

  onChange = (id: string): void => {
    this.setState({
      id: id,
    })
  }

  addUser = async () => {
    const { id } = this.state
    const { available } = this.props

    this.setState({
      addingUser: true,
    })

    try {
      const user = available.find(x => x.id === id)
      const fetched = (await NestAPI.get(`/users/${id}`)) as any

      if (user) {
        UserActions.addUser(
          id,
          fetched.assignedRoles.some(x => x.name === 'Client')
        )
      }
    } catch (e) {
      toast.error(`Failed to assign user to the business.`)
    }

    this.setState({
      id: null,
      addingUser: false,
    })
  }

  render() {
    const { id } = this.state
    const {
      available,
      pages: {
        business: { users },
      },
    } = this.props
    return (
      <div className="mt-5">
        <h6>Add Another User</h6>
        <div className="row">
          <div className="col-8">
            <Select
              options={available.filter(x => !users.find(y => y.user_id === x.id))}
              value={available.find(x => x.id === id) || null}
              getOptionLabel={x => `${x.name} (${x.email})`}
              getOptionValue={x => `${x.id}`}
              onChange={(e: any) => this.onChange(e.id)}
            />
          </div>
          <div className="col-4">
            <button
              disabled={this.state.addingUser}
              className="btn btn-outline-primary"
              onClick={this.addUser}
            >
              Add{this.state.addingUser ? 'ing' : ''} User
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default Connect(UserSearch)
