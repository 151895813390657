import React from 'react'
import { toast } from 'react-toastify'
import Connect from 'app/redux/connect'
import { Connected } from 'app/interface/redux'
import ErrorActions from 'app/redux/actions/core/errors'
import SessionActions from 'app/redux/actions/core/session'
import { NestAPI } from '../../app/nestapi'

class Interceptor extends React.Component<Connected> {
  componentWillMount() {
    NestAPI.interceptors.response.use(this.handleSuccess, this.handleError)
  }

  handleSuccess = response => {
    if (response.data.body) {
      return Promise.resolve(response.data.body)
    } else {
      return Promise.resolve(response.data)
    }
  }

  handleError = error => {
    window.scrollTo(0, 0)

    if (error.response) {
      switch (error.response.status) {
        case 401:
          this.handleTokenError()
          break
        case 403:
          this.handlePermissionError()
          break
        case 422:
          this.handleValidationError(error.response.data)
          break
        default:
          return this.handleSystemError()
      }
    } else {
      this.handleRequestStuck()
    }

    return Promise.reject('Request failed')
  }

  handleSystemError = () => {
    this.props.history.push('/admin/500')
  }

  handleTokenError = () => {
    if (this.props.location.pathname !== '/admin/login') {
      SessionActions.logout()
      this.props.history.push('/admin/login')
    }
  }

  handleValidationError = ({ data }) => {
    const columns = Object.keys(data)
    const errors = columns.map(x => ({
      column: x,
      message: data[x][0],
    }))
    ErrorActions.setErrors(errors)
    toast.error('Unable to save due to invalid data')
  }

  handlePermissionError = async () => {
    if (this.props.core.session.active) {
      this.props.history.push('/admin/control-panel')
      toast.error(`You're not allowed to do this!`)
    } else {
      this.props.history.push('/admin/login')
    }
  }

  handleRequestStuck = () => {
    this.props.history.push('/admin/500')
  }

  render() {
    return null
  }
}

export default Connect(Interceptor)
